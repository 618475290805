import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

import { NAVYS_PROJECT } from '@/referentials/routes';

import Layout from '@/components/Layout';
import SoloPicture from '@/components/Portfolio/SoloPicture';
import DoublePictures from '@/components/Portfolio/DoublePictures';
import ContentSection from '@/components/Portfolio/ContentSection';
import AppReviews from '@/components/Portfolio/AppReviews';
import DownloadApp from '@/components/Portfolio/DownloadApp';
import NextProject from '@/components/Portfolio/NextProject';
import PortfolioHeader from '@/components/Portfolio/PortfolioHeader';

export default function LaFraise({ data }) {
  const { t } = useTranslation();

  const tags = [
    'internal',
    'ux',
    'mobile',
    'rn',
    'rails',
    'growthHacking',
    'aso',
  ];

  const images = [
    {
      source: data.ad1,
      alt: t('coverLaFraise'),
    },
    {
      source: data.ad2,
      alt: t('coverLaFraise'),
    },
  ];

  const reviews = [
    {
      platform: 'iOS',
      username: 'Militine',
      rating: 5,
      title: 'Super appli, fiable et pratique',
      content:
        'Cette appli m’a sauvée, que ce soit pour me donner des idées de plats ou bien au moment de faire les courses ;)',
    },
    {
      platform: 'Android',
      username: 'Ophélie G.',
      rating: 5,
      title: 'Pratique',
      content:
        "Application vraiment très pratique pour les futurs mamans. Si on a un doute sur ce qu'on a le droit ou pas le droit de manger pendant la grossesse, la 🍓 nous aide énormément. Pour faire les courses c'est donc plus simple, on connaît les risques pour chaque aliment ... Merci à l'équipe d'avoir créé cette magnifique appli qui plus est, simple d'utilisation !!",
    },
    {
      platform: 'iOS',
      username: 'Craakette',
      rating: 5,
      title: 'Merci aux développeurs !!',
      content:
        "Grâce à l'application La Fraise plus de doute, pas besoin de réfléchir ! C'est tellement plus simple et en plus c'est gratuit et sans pub ! Merci beaucoup ça aurait été beaucoup plus compliqué sans vous",
    },
    {
      platform: 'Android',
      username: 'Marine Camus',
      rating: 5,
      title: 'Super appli',
      content:
        "Super application, je l'utilise au quotidien et c'est tellement facile d'utilisation ! Je recommande à 100% pour les futures mamans 😊",
    },
    {
      platform: 'iOS',
      username: 'Zouniote',
      rating: 5,
      title: 'Je recommande avec plaisir !',
      content:
        'Merci pour cette appli efficace et agréable à utiliser, grâce à un fonctionnement simple, un beau graphisme et une absence de pub !',
    },
    {
      platform: 'iOS',
      username: 'alison3131',
      rating: 5,
      title: 'Un vrai coup de pouce !',
      content:
        "Première grossesse, pas immunisée contre la toxoplasmose, cette appli m'a vraiment aidée dans mon quotidien. Simple d'utilisation, rapide et surtout... SANS PUB et SANS RIEN PAYER. Vraiment, Merci La Fraise vous m'avez vraiment facilité les courses !!",
    },
  ];

  return (
    <Layout description={t('description')}>
      <PortfolioHeader
        title={t('title')}
        websites={[t('website')]}
        description={t('description')}
        tags={tags}
      />

      <SoloPicture source={data.cover} alt={t('coverLaFraise')} />

      <ContentSection title={t('paragraph1Title')} content={t('paragraph1')} />

      <DoublePictures pictures={images} />

      <ContentSection title={t('growthTitle')} content={t('growthContent')} />

      <AppReviews reviews={reviews} />

      <DownloadApp
        iOSLink="https://apps.apple.com/fr/app/la-fraise/id1492431752"
        androidLink="https://play.google.com/store/apps/details?id=app.lafraise"
      />

      <NextProject title={t('nextProject')} to={NAVYS_PROJECT} />
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["lafraise", "general"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    cover: file(relativePath: { eq: "projects/la-fraise/app-screens.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    ad1: file(relativePath: { eq: "projects/la-fraise/ad1.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    ad2: file(relativePath: { eq: "projects/la-fraise/ad2.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`;
