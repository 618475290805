import React, { useState } from 'react';
import classNames from 'classnames';

import IOS from '@/assets/svg/iOS.svg';
import Android from '@/assets/svg/Android.svg';

import Star from '@/assets/svg/star.svg';
import HalfStar from '@/assets/svg/star-half.svg';

interface AppReviewProps {
  review: {
    username: string;
    rating: string;
    platform: string;
    title: string;
    content: string;
    link?: string;
  };
}

const AppReview: React.FC<AppReviewProps> = ({ review }) => {
  const [isOpen, setIsOpen] = useState(false);
  const CHAR_LIMIT = 173;

  return (
    <div
      className={classNames(
        'pt-[20px] pb-[30px] lg:py-[30px] px-[20px] lg:px-[42px] max-w-[472px] w-fit rounded-[40px] inline-block',
        'bg-primaryExtraLight text-black min-h-[269px] lg:min-h-[267px] mr-4 lg:pb-0',
      )}
    >
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-row">
          {[...Array(parseInt(review.rating, 10))].map((index, i) => (
            <Star key={i} />
          ))}
          {parseFloat(review.rating) - parseInt(review.rating, 10) > 0 && (
            <HalfStar />
          )}
        </div>
        {review.platform === 'iOS' ? <IOS /> : <Android />}
      </div>

      <p>{review.username}</p>
      <h3 className="text-2xl xl:text-xl mb-2 sg:text-2xl md:text-base">
        {review.title}
      </h3>
      {review.content.length < CHAR_LIMIT && (
        <p className="2xl:text-base lg:text-sm">{review.content}</p>
      )}
      {review.content.length >= CHAR_LIMIT && (
        <p className="2xl:text-base lg:text-sm md:text-sm lg:pb-1">
          {!isOpen && `${review.content.slice(0, CHAR_LIMIT)}...`}
          {isOpen && review.content}
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <span
            className="text-primary cursor-pointer hover:underline ml-2"
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen && 'Voir moins'}
            {!isOpen && 'Voir plus'}
          </span>
        </p>
      )}
    </div>
  );
};

export default AppReview;
