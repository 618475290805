import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './styles.css';

import ArrowComp from '@/components/ArrowComp';
import AppReview from '@/components/Portfolio/AppReviews/AppReview';

interface AppReviewsProps {
  reviews: {
    username: string;
    rating: number;
    platform: string;
    title: string;
    content: string;
    link?: string;
  }[];
}

const AppReviews: React.FC<AppReviewsProps> = ({ reviews }) => {
  const ref = useRef<Slider>(null);
  const [index, setIndex] = useState(0);

  return (
    <>
      <Slider
        afterChange={(i) => setIndex(i)}
        dots={false}
        ref={ref}
        infinite={false}
        speed={750}
        slidesToShow={3}
        slidesToScroll={1}
        arrows={false}
        responsive={[
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              centerMode: false,
            },
          },
          {
            breakpoint: 650,
            settings: {
              slidesToShow: 1,
              centerMode: false,
            },
          },
        ]}
      >
        {reviews.map((review, i) => (
          <AppReview key={i} review={review} isActive={i === index} />
        ))}
      </Slider>

      <div className="flex gap-5 mt-12">
        <ArrowComp
          onClick={() => ref?.current?.slickPrev()}
          disabled={index === 0}
        />
        <ArrowComp
          isNext
          onClick={() => ref?.current?.slickNext()}
          disabled={index === reviews.length - 1}
        />
      </div>
    </>
  );
};

export default AppReviews;
